export const BRANDS = {
  GX: 'gx',
  LEADOKU: 'leadoku',
  INFINITE: 'infinite',
  DIGITALMARK: 'digitalmark',
};

const brand = process.env.NX_BRAND || BRANDS.GX;

const color = {gx: '#2197ff', leadoku: '#9e6de4', infinite: '#70c4cf', digitalmark: '#111527'};

const getCssVariables = brand => {
  return [
    {name: '--color-primary', value: color[brand]},
    {name: '--color-tooltip-background', value: color[brand]},
    {name: '--color-ant-badge-sup', value: color[brand]},
    {name: '--color-ant-submenu-selected-a', value: color[brand]},
    {name: '--color-campaign-name', value: color[brand]},
    {name: '--color-potential-column-container', value: color[brand]},
    {name: '--color-user-email', value: color[brand]},
    {name: '--color-campaign-page-action-checkmark', value: color[brand]},
    {name: '--color-conversation-your-turn', value: color[brand]},
    {name: '--color-table-column-title', value: color[brand]},
    {name: '--color-tooltip-icon', value: color[brand]},
    {name: '--color-account-stats-and-hover', value: color[brand]},
    {name: '--color-campaign-back-button', value: color[brand]},
    {name: '--color-selection-actions-button', value: color[brand]},
    {name: '--color-radio-button-checked', value: color[brand]},
    {name: '--color-conversation-message', value: color[brand]},
    {name: '--color-action-menu-item', value: color[brand]},
    {name: '--color-login-button-hover', value: color[brand]},
    {name: '--color-logo-span', value: color[brand]},
    {name: '--color-connection-conversation-cell', value: color[brand]},
    {name: '--color-conversation-message-content-markup', value: color[brand]},
    {name: '--color-conversation-button-enabled', value: color[brand]},
    {name: '--color-conversation-link-info', value: color[brand]},
    {name: '--color-notifications-drawer-read-button', value: color[brand]},
    {name: '--color-icon-sync', value: color[brand]},
  ];
};

const config = {
  gx: {
    expertTitle: 'Growth',
    name: 'Growth-X',
    contact: 'help@growth-x.com',
    web: 'help@growth-x.com',
    webapp: 'https://web.growth-x.com',
    intercomAppId: 'skagyikw',
    intercomFeature: true,
    zapierFeature: true,
    zapierApp: 'pjg0zneFOlXD7zUSrUKpyZZzigXyOyK56MrH2yFx',
    salesforceFeature: true,
    hubspotFeature: true,
    isGx: true,
    brand: BRANDS.GX,
    documentTitle: 'Growth-X | Automation Tools from Growth Hackers',
    primaryColor: color.gx,
    variables: [
      ...getCssVariables(brand),
      {name: '--color-login-button-hover', value: '#286090'},
      {name: '--color-logo-span', value: '#337ab7'},
    ],
    gtmId: 'GTM-MQJFGNX',
    articles: {
      invitesVolume: 'https://help.growth-x.com/en/articles/4057678-invites-volume-feature',
      trackableUrl: 'https://help.growth-x.com/en/articles/5945653-trackable-urls',
      botCycle: 'https://help.growth-x.com/en/articles/6304103-understanding-the-growth-x-bot-cycles',
      multipleSalesNavigator: 'https://help.growth-x.com/en/articles/6925025-multiple-sales-navigator-subscriptions',
      excludeSpecificCompanies:
        'https://help.growth-x.com/en/articles/5574980-blacklist-of-companies',
      convertSavedLeads: 'https://help.growth-x.com/en/articles/4581953-convert-saved-leads-to-a-custom-list',
      acquisitionCampaignEmail: 'https://help.growth-x.com/en/articles/5397854-acquisition-campaign-email',
      profileViewLimit: 'https://help.growth-x.com/en/articles/6135949-profile-view-limit-reached',
      acquisitionCsv: 'https://help.growth-x.com/en/articles/3675108-acquisition-campaigns-using-emails-csv',
      errorMessageUi: 'https://help.growth-x.com/en/articles/6150148-error-message-for-ui-incompatibility',
      integrateZapier: 'https://help.growth-x.com/en/articles/2521832-how-to-integrate-growth-x-with-your-crm',
      instantlyInstructions: 'https://help.growth-x.com/en/articles/10270937-website-visitors-via-instantly-source',
    },
    sf_oauth2_client_id: '3MVG9JEx.BE6yifOGKUTfWSiGzeUfEnr8DyFVzbzEgCdLDl7p.fhIysDT39nrzp3MMDan2NnkmdfnUV_4yTOF',
    sf_oauth2_app_id: 'GX',
    hs_oauth2_client_id: '360b0d7b-235d-489e-a29c-f70d69b34f95',
    hs_oauth2_app_id: 'GX',
    instantlyFeature: true,
    instantly_link: 'https://api.growth-x.com/instantly/',
    rb2bFeature: true,
    rb2b_link: 'https://api.growth-x.com/rb2b/',
  },
  leadoku: {
    expertTitle: 'Leadoku',
    name: 'Leadoku',
    web: 'help@leadoku.io',
    contact: 'us via in-app chat',
    webapp: 'https://app.leadoku.io',
    intercomFeature: true,
    zapierFeature: true,
    zapierApp: 'upcXINdtL5qnmIaBx3Xaz1AkdlAQCZV5CtwykvkF',
    salesforceFeature: true,
    hubspotFeature: true,
    isLeadoku: true,
    intercomAppId: 'skagyikw',
    brand: BRANDS.LEADOKU,
    documentTitle: 'Leadoku',
    primaryColor: color.leadoku,
    variables: [
      ...getCssVariables(brand),
      {name: '--color-login-button-hover', value: '#c099f0'},
      {name: '--color-logo-span', value: '#7e5ab2'},
    ],
    gtmId: 'GTM-W4ZR2ZN7',
    articles: {
      invitesVolume: 'https://help.leadoku.io/en/articles/8261582-invite-volume-managemen-with-leadoku',
      trackableUrl: 'https://help.leadoku.io/en/articles/8261548-trackable-urls',
      botCycle: 'https://help.leadoku.io/en/articles/8261542-understanding-the-leadoku-bot-cycles',
      multipleSalesNavigator: 'https://help.leadoku.io/en/articles/8261527-multiple-sales-navigator-subscriptions',
      excludeSpecificCompanies:
        'https://help.leadoku.io/en/articles/8261642-how-do-i-exclude-specific-companies-from-my-campaign',
      convertSavedLeads: 'https://help.leadoku.io/en/articles/8261576-convert-saved-leads-to-a-custom-list',
      acquisitionCampaignEmail: 'https://help.leadoku.io/en/articles/8261569-acquisition-campaign-leadoku',
      profileViewLimit: 'https://help.leadoku.io/en/articles/8970454-profile-view-limit',
      acquisitionCsv: 'https://help.leadoku.io/en/articles/8501684-acquisition-campaigns-using-linkedin-urls-csv-file',
      errorMessageUi: 'https://help.leadoku.io/en/articles/6150148-error-message-for-ui-incompatibility',
      integrateZapier: 'https://help.leadoku.io/en/articles/8261622-how-to-integrate-leadoku-with-zapier',
    },
    sf_oauth2_client_id: '3MVG9JEx.BE6yifOGKUTfWSiGzQAxtK5v073zU7TFeB.6vmiCyMt8oXfXOj4j1UKq.Ylfv5mrNFKoWKhwgeP0',
    sf_oauth2_app_id: 'LK',
    hs_oauth2_client_id: 'c39919d7-1405-4087-88aa-f28f16ae0d39',
    hs_oauth2_app_id: 'LK',
    instantlyFeature: true,
    instantly_link: 'https://api.leadoku.io/instantly/',
    rb2bFeature: true,
    rb2b_link: 'https://api.leadoku.io/rb2b/',
  },
  infinite: {
    expertTitle: 'Infinite',
    name: 'Infinite',
    web: 'help@infinite.co.il',
    contact: 'us via in-app chat',
    webapp: 'https://app.infinite.io',
    intercomFeature: false,
    zapierFeature: false,
    salesforceFeature: false,
    hubspotFeature: false,
    isAffiliate: true,
    isInfinite: true,
    brand: BRANDS.INFINITE,
    documentTitle: 'Infinite',
    primaryColor: color.infinite,
    variables: [
      ...getCssVariables(brand),
      {name: '--color-login-button-hover', value: '#9ad6db'},
      {name: '--color-logo-span', value: '#1a6f6d'},
    ],
    articles: {
      invitesVolume: 'http://help.growth-x.com/en/articles/4057678-invites-volume-feature',
      trackableUrl: 'https://help.growth-x.com/en/articles/5945653-trackable-urls',
      botCycle: 'http://help.growth-x.com/en/articles/6304103-understanding-the-growth-x-bot-cycles',
      multipleSalesNavigator: 'https://help.growth-x.com/en/articles/6797509-multiple-sales-navigator-subscription',
      excludeSpecificCompanies:
        'https://help.growth-x.com/en/articles/5574980-blacklist-of-companies',
      convertSavedLeads: `https://help.growth-x.com/en/articles/4581953-convert-saved-leads-to-a-custom-list`,
      acquisitionCampaignEmail: 'https://help.growth-x.com/en/articles/5397854-acquisition-campaign-email',
      profileViewLimit: 'https://help.growth-x.com/en/articles/6135949-profile-view-limit-reached',
      acquisitionCsv: 'https://help.growth-x.com/en/articles/3675108-acquisition-campaigns-using-emails-csv',
      errorMessageUi: 'https://help.growth-x.com/en/articles/6150148-error-message-for-ui-incompatibility',
    },
  },
  digitalmark: {
    expertTitle: 'Digital Mark',
    name: 'Digital Mark',
    web: 'help@digitalmark.ai',
    contact: 'us via in-app chat',
    webapp: 'https://app.digitalmark.ai/',
    intercomFeature: false,
    zapierFeature: false,
    salesforceFeature: false,
    hubspotFeature: false,
    isAffiliate: true,
    isDigitalmark: true,
    brand: BRANDS.DIGITALMARK,
    documentTitle: 'Digital Mark',
    primaryColor: color.digitalmark,
    variables: [
      ...getCssVariables(brand),
      {name: '--color-login-button-hover', value: '#111527'},
      {name: '--color-logo-span', value: '#111527'},
    ],
    articles: {
      invitesVolume: 'http://help.growth-x.com/en/articles/4057678-invites-volume-feature',
      trackableUrl: 'https://help.growth-x.com/en/articles/5945653-trackable-urls',
      botCycle: 'http://help.growth-x.com/en/articles/6304103-understanding-the-growth-x-bot-cycles',
      multipleSalesNavigator: 'https://help.growth-x.com/en/articles/6797509-multiple-sales-navigator-subscription',
      excludeSpecificCompanies:
        'https://help.growth-x.com/en/articles/5574980-blacklist-of-companies',
      convertSavedLeads: `https://help.growth-x.com/en/articles/4581953-convert-saved-leads-to-a-custom-list`,
      acquisitionCampaignEmail: 'https://help.growth-x.com/en/articles/5397854-acquisition-campaign-email',
      profileViewLimit: 'https://help.growth-x.com/en/articles/6135949-profile-view-limit-reached',
      acquisitionCsv: 'https://help.growth-x.com/en/articles/3675108-acquisition-campaigns-using-emails-csv',
      errorMessageUi: 'https://help.growth-x.com/en/articles/6150148-error-message-for-ui-incompatibility',
    },
  },
};

export const brandConfig = config[brand];
