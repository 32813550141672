export class HsOAuth2Service {
  client: any;
  constructor({client}: any) {
    this.client = client;
  }
  serviceName = 'hubspot';

  getAuthorizedCredentials() {
    return this.client.get(`${this.serviceName}/get-details`, {});
  }
  setAuthorizationCode(params) {
    return this.client.post(`${this.serviceName}/authorize`, params);
  }
  clearAuthorizedCredentials() {
    return this.client.delete(`${this.serviceName}/delete-details`, {});
  }
  getUsers() {
    return this.client.get(`${this.serviceName}/users`, {});
  }
  getSearchProperty(params) {
    return this.client.get(`${this.serviceName}/search_property`, params);
  }
  getPipelines() {
    return this.client.get(`${this.serviceName}/pipelines`, {});
  }
  configureHubspot(params) {
    return this.client.post(`${this.serviceName}/configure-hubspot`, params);
  }
  syncRetroactively() {
    return this.client.post(`${this.serviceName}/sync_retro`, {});
  }
}
