import {Modal, Row, Radio, Col, Button, Divider} from 'antd';
import React from 'react';

import '../ChoosePlanLeadokuModal/ChoosePlanLeadokuModal.scss';
import checkMark from '../../assets/images/grntck.png';

export const ChoosePlanGxModal = ({visible, onCancel, period, onChangePeriod, onClick}) => (
  <Modal visible={visible} footer={null} destroyOnClose={true} onCancel={onCancel} width={800} title="Checkout">
    <div className="plans_container gx">
      <Row type="flex" justify="center" style={{marginBottom: 45}}>
        <Radio.Group className="choose_plan" value={period} buttonStyle="solid" onChange={onChangePeriod}>
          <Radio.Button value="monthly">Monthly</Radio.Button>
          <Radio.Button value="annual">Annual</Radio.Button>
        </Radio.Group>
      </Row>
      <Row gutter={[32, 8]}>
        {GX_PLANS.map(plan => (
          <Col key={plan.key} span={12}>
            <div className="plan_container">
              <h3>{plan.title}</h3>
              <div className="price_container">
                <span className="price">{plan.prices[period].price}</span>
                <span className="description">{plan.prices[period].description}</span>
              </div>
              <Button type="primary" onClick={() => onClick(plan)}>
                {plan.button}
              </Button>
              {plan.features.map((feature: JSX.Element) => feature)}
            </div>
          </Col>
        ))}
      </Row>
    </div>
  </Modal>
);

const GX_PLANS = [
  {
    key: 'core',
    title: 'Core',
    prices: {
      monthly: {
        price: '$97',
        description: '/mo',
      },
      annual: {
        price: '$87',
        description: '/mo',
      },
    },
    button: 'Get Started Now',
    features: [
      <ul key="list_1">
        <li style={{listStyleImage: `url(${checkMark})`}}>
          <b key="free">Everything in Free</b>
        </li>
        <li style={{listStyleImage: `url(${checkMark})`}}>Personalized Message Sequences</li>
        <li style={{listStyleImage: `url(${checkMark})`}}>Unlimited templates library</li>
        <li style={{listStyleImage: `url(${checkMark})`}}>Tags and notes for conversation tracking</li>
        <li style={{listStyleImage: `url(${checkMark})`}}>Built-in duplicate detection</li>
        <li style={{listStyleImage: `url(${checkMark})`}}>CRM integration capabilities</li>
      </ul>,
      <Divider key="devider_1" style={{height: 2}} />,
      <ul key="list_2">
        <li style={{listStyleImage: `url(${checkMark})`}}>AI-driven hot lead identification</li>
        <li style={{listStyleImage: `url(${checkMark})`}}>AI-assisted rapid campaign creation</li>
      </ul>,
      <Divider key="devider_2" style={{height: 2}} />,
      <ul key="list_3">
        <li style={{listStyleImage: `url(${checkMark})`}}>Website Visitors Campaign via Instantly</li>
        <li style={{listStyleImage: `url(${checkMark})`}}>API/Webhook/Zapier Campaign Sources</li>
        <li style={{listStyleImage: `url(${checkMark})`}}>Upload your own list</li>
        <li style={{listStyleImage: `url(${checkMark})`}}>
          Retargeting Google Ads, Meta Ads, X Ads. LinkedIn ads. and more
        </li>
        <li style={{listStyleImage: `url(${checkMark})`}}>Sales Navigator LinkedIn Search</li>
        <li style={{listStyleImage: `url(${checkMark})`}}>Nurturing Campaigns</li>
        <li style={{listStyleImage: `url(${checkMark})`}}>Email Automation via Instantly</li>
      </ul>,
      <Divider key="devider_3" style={{height: 2}} />,
      <ul key="list_4">
        <li style={{listStyleImage: `url(${checkMark})`}}>Whitelabel (10+ users required)</li>
      </ul>,
    ],
  },
  {
    key: 'starter',
    title: 'Accelerate',
    prices: {
      monthly: {
        price: '$297',
        description: '/mo',
      },
      annual: {
        price: '$287',
        description: '/mo',
      },
    },
    button: 'Get Started Now',
    features: [
      <ul key="list_1">
        <li style={{listStyleImage: `url(${checkMark})`}}>
          <b>Everything in Core</b>
        </li>
        <li style={{listStyleImage: `url(${checkMark})`}}>
          <span style={{textDecoration: 'underline'}}>Dedicated Growth Expert</span>
        </li>
        <li style={{listStyleImage: `url(${checkMark})`}}>1:1 Training Sessions</li>
        <li style={{listStyleImage: `url(${checkMark})`}}>Priority Support</li>
        <li style={{listStyleImage: `url(${checkMark})`}}>Campaign Review & Optimization</li>
        <li style={{listStyleImage: `url(${checkMark})`}}>Profile Review & Optimization</li>
        <li style={{listStyleImage: `url(${checkMark})`}}>API/CRM Assisted Integration</li>
        <li style={{listStyleImage: `url(${checkMark})`}}>Executive Analytics</li>
        <li style={{listStyleImage: `url(${checkMark})`}}>Unlimited Outreach</li>
        <li style={{listStyleImage: `url(${checkMark})`}}>Data Credits</li>
      </ul>,
    ],
  },
];
