import {Button, Input, Row, Form, Alert} from 'antd';
import {Formik, FormikValues} from 'formik';
import React from 'react';
import * as Yup from 'yup';

import {InstantlyLink} from './InstantlyLink';

const ConnectSchema = Yup.object().shape({
  apiKey: Yup.string().required('Required'),
});

export const Connect = ({loading, connect, error}) => {
  return (
    <Formik
      onSubmit={(values: FormikValues) => connect(values.apiKey)}
      initialValues={{apiKey: ''}}
      validationSchema={ConnectSchema}
    >
      {({values, errors, touched, dirty, setFieldValue, handleSubmit}) => (
        <Form onSubmit={handleSubmit}>
          <Row type="flex" className="instantly-connect">
            <Form.Item
              validateStatus={errors.apiKey && touched.apiKey ? 'error' : ''}
              hasFeedback
              help={errors.apiKey && touched.apiKey ? errors.apiKey : ''}
              extra={<InstantlyLink />}
            >
              <Input
                name="apiKey"
                placeholder={'Instantly API Key'}
                value={values.apiKey}
                onChange={e => setFieldValue('apiKey', e.target.value)}
              />
            </Form.Item>
            <Button htmlType="submit" type="primary" loading={loading} disabled={!dirty}>
              Connect
            </Button>
          </Row>
          {error && <Alert message={error} type="error" showIcon style={{marginTop: 5}} />}
        </Form>
      )}
    </Formik>
  );
};
