import moment from 'moment-timezone';
import React, {useMemo} from 'react';

import {brandConfig, STRINGS} from '../../utils';
import UpgradeButton from './UpgradeButtons';
import {getLogo} from './utils';
import './Logo.scss';

const env = process.env.NX_ENV || 'production';

const getDaysLeft = (trialEndDate: string) => {
  if (!trialEndDate) {
    return 0;
  }
  return moment.utc(trialEndDate).diff(moment(), 'days');
};

const getClientType = client => {
  let type = client.type;
  if (client.type === 'starter' && client.signature_enabled) {
    type = 'Freemium';
  } else if (client.type === 'trial' && !client.subscription_account_id) {
    type = null;
  } else if (client.type === 'trial' && client.subscription_account_id) {
    type = client.awaiting_subscription_activation ? 'Expired Trial' : 'Cancelled';
  } else if ((client.type === 'starter' || client.type === 'core') && client.trial_active) {
    type = 'Active Trial';
  } else if (client.type === 'core') {
    type = 'Core';
  } else if (client.type === 'starter' && client.is_free_sub) {
    type = 'Free';
  } else if (client.type === 'starter') {
    type = brandConfig.isGx ? 'Accelerate' : 'Startup';
  }
  return type ? ` [${type}]` : '';
};

export const Logo = ({
  client,
  onUpgrade,
  isAdmin,
  setHeaderModal,
  activateRegularPlan,
  onDemoSubmit,
  activateRegularPlanGx,
}: any) => {
  const openBilling = () => setHeaderModal('subscription');
  let versionText = isAdmin ? client.app_version : '';
  versionText += env !== 'production' ? ` ${env}` : '';
  versionText += getClientType(client);
  versionText += client && isAdmin ? ' [admin mode]' : '';
  const daysLeft = useMemo(() => getDaysLeft(client.trial_end_date), [client.trial_end_date]);

  const LogoImage = getLogo();
  return (
    <div className="logo">
      <LogoImage className="logo__img" />
      <div className="version">
        <div className="text-primary text-smaller">{versionText}</div>
        <div>
          <UpgradeButton
            client={client}
            onUpgrade={onUpgrade}
            openBilling={openBilling}
            activateRegularPlan={activateRegularPlan}
            onDemoSubmit={onDemoSubmit}
            activateRegularPlanGx={activateRegularPlanGx}
          />
          {client && client.type === 'on-hold' && (
            <span className="trial-days">{STRINGS.info_subscription_onhold}</span>
          )}
          {client && (client.type === 'starter' || client.type === 'core') && client.trial_active && (
            <span className="trial-days">{`You have ${daysLeft} days left of your trial.`}</span>
          )}
        </div>
      </div>
    </div>
  );
};
