import {StorageService} from '../../services';
import {clientSettingsInitialState, settingsInitialState} from '../../utils/constants';
import {LOGIN_SUCCESS, LOGOUT, SIGNUP_SUCCESS, INIT_SUCCESS} from '../client';
import {SET_SETTINGS} from './settingsActions';

export const settingsReducers = (state = settingsInitialState, action: any) => {
  switch (action.type) {
    case SET_SETTINGS: {
      const newState = {...state, ...action.settings};
      StorageService.setItem('settings', JSON.stringify(newState));
      return newState;
    }
    case SIGNUP_SUCCESS:
    case INIT_SUCCESS:
    case LOGIN_SUCCESS: {
      const clientState = {
        [action.client.id]: {
          ...clientSettingsInitialState,
          ...state[action.client.id],
        },
      };
      const newState = {
        ...state,
        ...clientState,
        isAdmin: action.isAdmin !== undefined ? action.isAdmin : Boolean(state.isAdmin),
      };
      StorageService.setItem('settings', JSON.stringify(newState));
      return newState;
    }
    case LOGOUT: {
      const newState = {...state, isAdmin: false};
      StorageService.setItem('settings', JSON.stringify(newState));
      return newState;
    }
    default:
      return state;
  }
};
