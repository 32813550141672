import {Button, Spin, Row, message, Select, Form, Input, Col, Icon, Tooltip, Popconfirm} from 'antd';
import {Formik} from 'formik';
import {debounce} from 'lodash';
import React, {useEffect, useRef, useCallback, useState} from 'react';

import './hubspot.scss';

import {brandConfig, CONSTANTS, InfoIcon} from '@growth-x/ui';
import {SwitchRow} from '../campaignDetails/common';

const {Option} = Select;
const InputGroup = Input.Group;

const HubSpotPortalFieldChoices = {
  user: 'User',
  campaign: 'Campaign',
  phone: 'Receiver Phone',
  connection_date: 'Connection Date',
  response: 'Response',
  full_conversation: 'Full Conversation',
  receiver_location: 'Receiver Location',
  receiver_linkedin_url: 'Receiver Linkedin Url',
  receiver_twitter_url: 'Receiver Twitter Url',
  receiver_websites: 'Receiver Websites',
  receiver_birthday: 'Receiver Birthday',
  receiver_headline: 'Receiver Headline',
  receiver_companies: 'Receiver Companies',
  receiver_positions: 'Receiver Positions',
  receiver_response_date: 'Receiver latest response date',
  receiver_ims: 'Receiver IMs',
  receiver_tags: 'Receiver Tags',
  receiver_imported: 'Receiver Imported',
  receiver_followed: 'Receiver Followed on X(Twitter)',
  receiver_clicked_links: 'Receiver Clicked Links',
  receiver_incomplete: 'Receiver Incomplete',
};

const DEFAULT_FIELDS_TO_SYNC = [
  'receiver_email',
  'receiver_first_name',
  'receiver_last_name',
  'receiver_company',
];

const getEmptyDealToTag = () => ({
  selected_pipeline: undefined,
  pipeline_id: undefined,
  pipeline_label: undefined,
  deal_stage_id: undefined,
  id: Date.now(),
  tag_name: undefined,
  deal_stage_label: null,
});

export const Hubspot = props => {
  const POPUP_HEIGHT = 500;
  const POPUP_WIDTH = 500;
  const popupRef = useRef(null);
  const intervalRef = useRef(null);

  const {
    oauth2,
    setAuthorizationCode,
    getAuthorizedCredentials,
    clearAuthorizedCredentials,
    getUsers,
    getSearchProperty,
    getPipelines,
    configure,
    syncRetroactively,
    tags,
  } = props;

  const [authorized, setAuthorized] = useState(false);
  const [addDealToTagMode, setAddDealToTagMode] = useState(false);
  const [newDealToTag, setNewDealToTag] = useState(getEmptyDealToTag());

  useEffect(() => {
    getAuthorizedCredentials();
  }, [getAuthorizedCredentials]);

  useEffect(() => {
    if (!authorized && oauth2?.authorized) {
      setAuthorized(true);
      getUsers();
      getPipelines();
    }
  }, [oauth2]);

  const generateState = () => {
    const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let array = new Uint8Array(40);
    window.crypto.getRandomValues(array);
    array = array.map((x: number) => validChars.codePointAt(x % validChars.length));
    const randomState = String.fromCharCode.apply(null, array);
    return randomState;
  };

  const findPipelineLabel = (pipelines, pipelineId) => {
    return pipelines.find(p => p.id === pipelineId)?.label || '-';
  };

  const handleSubmit = data => {
    configure({
      hubspot_user_id: data.hubspot_user_id || null,
      custom_li_url_property_name: data.custom_li_url_property_name || null,
      portal_config: {
        deal_stage_to_tag_feature: data.deal_stage_to_tag_feature,
        fields_to_sync: data.fields_to_sync.filter(field => !DEFAULT_FIELDS_TO_SYNC.includes(field)),
        stage_to_tags: data.stage_to_tags.map(el => ({
          deal_stage_id: el.deal_stage_id,
          deal_stage_label: el.deal_stage_label,
          tag_name: el.tag_name,
          pipeline_id: el.pipeline_id,
          pipeline_label: el.pipeline_label,
        })),
      },
    });
  };

  const onChangeDealStage = newValue => {
    const selectedStage = oauth2?.pipelines
      ?.find(p => p.id === newDealToTag.selected_pipeline)
      ?.stages?.find(s => s.id === newValue);
    if (selectedStage) {
      setNewDealToTag({
        ...newDealToTag,
        deal_stage_id: selectedStage.id,
        deal_stage_label: selectedStage.label,
      });
    }
  };

  const getStagesList = values => {
    let alreadyUsedStages = values?.stage_to_tags.map(stage => stage.deal_stage_id);
    let selectedPipelineStages = oauth2?.pipelines?.find(p => p.id === newDealToTag.selected_pipeline)?.stages || [];
    if (selectedPipelineStages) {
      selectedPipelineStages = selectedPipelineStages.filter(stage => !alreadyUsedStages.includes(stage.id));
    }
    return selectedPipelineStages;
  };

  const onAddStageToTagMapping = (values, setFieldValue) => {
    if (newDealToTag.selected_pipeline && newDealToTag.deal_stage_id && newDealToTag.tag_name) {
      setFieldValue('stage_to_tags', [...values.stage_to_tags, newDealToTag]);
      setNewDealToTag(getEmptyDealToTag());
      setAddDealToTagMode(false);
    }
  };

  const handleSearchPropertyName = debounce(value => {
    if (value) {
      getSearchProperty({property_name: value});
    }
  }, 750);
  const saveState = (state: string) => {
    sessionStorage.setItem(CONSTANTS.hs_oauth2_state_key, state);
  };

  const getFiledNameOptions = () => {
    let options = oauth2?.propertyNameOptions;
    if (oauth2?.custom_li_url_property_name) {
      options = [
        {name: oauth2?.custom_li_url_property_name, label: oauth2?.custom_li_url_property_label},
        ...oauth2?.propertyNameOptions,
      ];
    }
    return options ? options.map(option => <Select.Option key={option.name}>{option.label}</Select.Option>) : null;
  };

  const removeState = () => {
    sessionStorage.removeItem(CONSTANTS.hs_oauth2_state_key);
  };

  const openPopup = url => {
    const top = window.outerHeight / 2 + window.screenY - POPUP_HEIGHT / 2;
    const left = window.outerWidth / 2 + window.screenX - POPUP_WIDTH / 2;
    return window.open(url, 'OAuth2 Popup', `height=${POPUP_HEIGHT},width=${POPUP_WIDTH},top=${top},left=${left}`);
  };

  const enhanceAuthorizeUrl = (state, redirect_uri) => {
    return `${CONSTANTS.hs_oauth2_url}&client_id=${brandConfig.hs_oauth2_client_id}&redirect_uri=${redirect_uri}&scope=${CONSTANTS.hs_oauth2_scope}&optional_scope=${CONSTANTS.hs_oauth2_optional_scope}&state=${state}`;
  };

  const closePopup = popupRef => {
    popupRef.current?.close();
  };

  const cleanup = useCallback((popupRef, handleMessageListener) => {
    closePopup(popupRef);
    removeState();
    window.removeEventListener('message', handleMessageListener);
  }, []);

  const getAuthCode = useCallback(() => {
    const redirect_uri = window.location.origin + CONSTANTS.hs_oauth2_redirect_path;
    const state = generateState();
    saveState(state);
    popupRef.current = openPopup(enhanceAuthorizeUrl(state, redirect_uri));

    async function handleMessageListener(msg) {
      if (msg.origin !== window.location.origin) {
        return;
      }
      try {
        const type = msg && msg.data && msg.data.type;
        if (type === CONSTANTS.hs_oauth2_message_response) {
          const errorMaybe = msg && msg.data && msg.data.error;
          if (errorMaybe) {
            message.error(errorMaybe);
          } else {
            const code = msg && msg.data && msg.data.payload && msg.data.payload.code;
            const app_id = brandConfig.hs_oauth2_app_id;
            if (code) {
              setAuthorizationCode({redirect_uri, code, app_id});
              getUsers();
            }
          }
          cleanup(popupRef, handleMessageListener);
        }
      } catch (genericError) {
        message.error(genericError);
        cleanup(popupRef, handleMessageListener);
      }
    }
    window.addEventListener('message', handleMessageListener);

    return () => {
      window.removeEventListener('message', handleMessageListener);
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [cleanup, setAuthorizationCode]);

  return (
    <Spin size="large" spinning={oauth2.isLoading}>
      {oauth2?.authorized ? (
        <Formik
          initialValues={{
            hubspot_user_id: oauth2?.hubspot_user_id,
            custom_li_url_property_name: oauth2?.custom_li_url_property_name,
            deal_stage_to_tag_feature: oauth2?.portal_config?.deal_stage_to_tag_feature,
            fields_to_sync: [...DEFAULT_FIELDS_TO_SYNC, ...oauth2?.portal_config?.fields_to_sync],
            stage_to_tags: oauth2?.portal_config?.stage_to_tags,
          }}
          onSubmit={handleSubmit}
        >
          {({values, dirty, handleSubmit, setFieldValue}) => {
            return (
              <Form layout="vertical" className="hubspot-form" onSubmit={handleSubmit}>
                <Form.Item
                  label={
                    <>
                      {'Hubspot User:'}
                      <InfoIcon
                        message={
                          'Enter the HubSpot user email to associate your Growth-X account to HubSpot user. If you have multiple Growth-X accounts you can associate each account to a HubSpot user. For example, when you use Add to Growth-X Campaign feature in HubSpot, You will only see associated accounts campaigns'
                        }
                      />
                    </>
                  }
                >
                  <Select
                    allowClear
                    value={values.hubspot_user_id}
                    placeholder={'Hubspot User'}
                    style={{width: '100%'}}
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    onChange={value => setFieldValue('hubspot_user_id', value)}
                    notFoundContent={null}
                  >
                    {oauth2?.users?.map(option => (
                      <Select.Option key={option.id}>{option.email}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={
                    <>
                      Hubspot Custom Field for LinkedIn Profile URL of the Contact:
                      <InfoIcon
                        message={
                          'Provide the custom field where you store LinkedIn URL’s. This will help Growth-X to better outreach receivers when using Add to Growth-X Campaign feature in HubSpot'
                        }
                      />
                    </>
                  }
                >
                  <Select
                    allowClear
                    showSearch
                    value={values.custom_li_url_property_name}
                    placeholder={'Type for search..'}
                    style={{width: '100%'}}
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    onSearch={handleSearchPropertyName}
                    onChange={value => setFieldValue('custom_li_url_property_name', value)}
                    notFoundContent={null}
                  >
                    {getFiledNameOptions()}
                  </Select>
                </Form.Item>
                <Form.Item label={'CRM Contact Fields:'}>
                  <Select
                    mode="multiple"
                    value={values.fields_to_sync}
                    onChange={value => setFieldValue('fields_to_sync', value)}
                  >
                    <Option value="receiver_email" disabled>
                      Email
                    </Option>
                    <Option value="receiver_first_name" disabled>
                      First Name
                    </Option>
                    <Option value="receiver_last_name" disabled>
                      Last Name
                    </Option>
                    <Option value="receiver_company" disabled>
                      Company
                    </Option>
                    {Object.entries(HubSpotPortalFieldChoices).map(([value, name]) => (
                      <Option value={value}>{name}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <SwitchRow
                  label={'Map Deal Stages to Tags:'}
                  isLock={false}
                  onChange={(checked: boolean) => setFieldValue('deal_stage_to_tag_feature', checked)}
                  checked={values.deal_stage_to_tag_feature}
                />
                {values.deal_stage_to_tag_feature && (
                  <div style={{marginTop: -10}}>
                    {values.stage_to_tags.map(elem => (
                      <Row className="hubspot-form__pipeline-mapping" type="flex">
                        <span>
                          <span>{`${findPipelineLabel(oauth2?.pipelines, elem.pipeline_id)} • `}</span>
                          <span style={{marginRight: 20}}>{`${elem.deal_stage_label}:`}</span>
                          <Icon type="tag" />
                          <span style={{marginLeft: 5}}>{`${elem.tag_name}`}</span>
                        </span>
                        <Tooltip title="Click to remove this deal stage to tag mapping.">
                          <Button
                            className="hubspot-form__remove-mapping-button"
                            size="small"
                            type="danger"
                            security="circle"
                            icon="close"
                            ghost
                            onClick={() =>
                              setFieldValue(
                                'stage_to_tags',
                                values.stage_to_tags.filter(el => el.id !== elem.id)
                              )
                            }
                          />
                        </Tooltip>
                      </Row>
                    ))}
                    {!addDealToTagMode ? (
                      <Row type="flex" style={{margin: '20px 0'}}>
                        <Button type="primary" onClick={() => setAddDealToTagMode(true)}>
                          Add New Deal Stage Mapping
                        </Button>
                      </Row>
                    ) : (
                      <Form layout="vertical" style={{margin: '20px 0', justifyContent: 'space-between'}}>
                        <Row className="hubspot-form__mapping-label-row" gutter={8}>
                          <Col span={16}>
                            <div style={{width: 155, display: 'inline-block'}}>Pipeline</div>
                            <div style={{width: 155, display: 'inline-block'}}>Stage</div>
                          </Col>
                          <Col span={8} style={{paddingLeft: 10}}>
                            Tag
                          </Col>
                        </Row>
                        <InputGroup style={{width: 'calc(100% - 60px)'}}>
                          <Row gutter={8}>
                            <Col span={16}>
                              <InputGroup compact style={{width: 310}}>
                                <Select
                                  placeholder="Pipeline name"
                                  style={{width: 155}}
                                  value={newDealToTag.selected_pipeline}
                                  onChange={v =>
                                    setNewDealToTag({
                                      ...newDealToTag,
                                      selected_pipeline: v,
                                      pipeline_id: v,
                                      pipeline_label: findPipelineLabel(oauth2?.pipelines, v),
                                    })
                                  }
                                  dropdownMatchSelectWidth={false}
                                >
                                  {oauth2?.pipelines?.map(p => (
                                    <Option value={p.id}>{p.label}</Option>
                                  ))}
                                </Select>
                                <Select
                                  placeholder="Pipeline stage"
                                  style={{width: 155}}
                                  value={newDealToTag.deal_stage_id}
                                  onChange={onChangeDealStage}
                                  dropdownMatchSelectWidth={false}
                                >
                                  {getStagesList(values).map(s => (
                                    <Option value={s.id}>{s.label}</Option>
                                  ))}
                                </Select>
                              </InputGroup>
                            </Col>
                            <Col span={8}>
                              <Select
                                placeholder="Tag"
                                style={{width: 130}}
                                value={newDealToTag.tag_name}
                                onChange={value => setNewDealToTag({...newDealToTag, tag_name: value})}
                                dropdownMatchSelectWidth={false}
                              >
                                {tags.map(tag => (
                                  <Option value={tag}>{tag}</Option>
                                ))}
                              </Select>
                            </Col>
                          </Row>
                        </InputGroup>
                        <Button type="primary" onClick={() => onAddStageToTagMapping(values, setFieldValue)}>
                          Add
                        </Button>
                      </Form>
                    )}
                  </div>
                )}

                <Row style={{justifyContent: 'space-between'}} type="flex">
                  <Button type="danger" onClick={() => clearAuthorizedCredentials()}>
                    Disconnect
                  </Button>
                  <div>
                    {oauth2 && !oauth2.retro_synced && (
                      <Popconfirm
                        title="This will sync all of your data to HubSpot, this is one time action"
                        onConfirm={syncRetroactively}
                        okText="Continue"
                        cancelText="Cancel"
                      >
                        <Button icon="sync">
                          Sync all data
                        </Button>
                      </Popconfirm>
                    )}
                    {dirty && (
                      <Button type="primary" htmlType="submit">
                        Save
                      </Button>
                    )}
                  </div>
                  
                </Row>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Row style={{justifyContent: 'flex-end'}} type="flex">
          <Button type="primary" onClick={() => getAuthCode()}>
            Connect
          </Button>
        </Row>
      )}
    </Spin>
  );
};
