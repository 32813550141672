export class ProxyService {
  client: any;
  serviceName = 'api/v2/proxy';
  constructor({client}: any) {
    this.client = client;
  }
  addUserCustomProxy(params) {
    return this.client.post(`${this.serviceName}/add-custom-proxy-to-user`, params);
  }
  addClientCustomProxy(params) {
    return this.client.post(`${this.serviceName}/add-custom-proxy-to-client`, params);
  }
  clearUserCustomProxy(params) {
    return this.client.post(`${this.serviceName}/remove-proxy-from-user`, params);
  }
  clearClientCustomProxy() {
    return this.client.post(`${this.serviceName}/remove-proxy-from-client`);
  }
}
