import {Button, Col, Form, Input, Row, Switch} from 'antd';
import React from 'react';

import {AdminFeatureIcon} from '@growth-x/ui';

interface UserProxyProps {
  proxy: any;
  errors: any;
  touched: any;
  values: any;
  setFieldValue: (field: string, value: any) => void;
  handleBlur: any;
  customProxyVisible: boolean;
  onSwitchCheckedChange: (value: boolean) => void;
  removeCustomProxy: () => void;
}

export function ProxySettings({
  proxy,
  errors,
  touched,
  values,
  setFieldValue,
  handleBlur,
  customProxyVisible,
  onSwitchCheckedChange,
  removeCustomProxy,
}: UserProxyProps) {
  const userLocation = proxy?.location;
  const currentProxy = userLocation && userLocation?.city ? userLocation.city : proxy?.city ? proxy.city : '';
  const isCustomProxy = !userLocation;
  if (proxy?.ip) {
    return (
      <Form.Item
        style={{marginTop: 10}}
        labelCol={{span: 10}}
        wrapperCol={{span: 14}}
        label={
          <span>
            Proxy IP location: <AdminFeatureIcon />
          </span>
        }
      >
        <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: -5}}>
          <div style={{textAlign: 'right', marginRight: 10}}>{`${proxy.ip}  ${
            currentProxy && `(${currentProxy})`
          }`}</div>
          {isCustomProxy && (
            <Button type="danger" onClick={removeCustomProxy}>
              Clear
            </Button>
          )}
        </div>
      </Form.Item>
    );
  }
  return (
    <>
      <Form.Item
        label={
          <span>
            Proxy IP location: <AdminFeatureIcon />
          </span>
        }
      >
        <Row type="flex" justify="space-around" align="middle">
          <Col span={18}>
            <div style={{fontWeight: 'bold'}}>Use custom proxy location</div>
          </Col>
          <Col span={6}>
            <Switch
              style={{float: 'right'}}
              checked={customProxyVisible}
              onChange={value => onSwitchCheckedChange(value)}
            />
          </Col>
        </Row>
      </Form.Item>
      {customProxyVisible && (
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Ip:"
              validateStatus={errors.custom_proxy_ip && touched.custom_proxy_ip ? 'error' : ''}
              hasFeedback
              help={errors.custom_proxy_ip && touched.custom_proxy_ip ? errors.custom_proxy_ip : ''}
            >
              <Input
                name="ip"
                value={values.custom_proxy_ip}
                placeholder="___.___.___.___"
                onChange={ev => setFieldValue('custom_proxy_ip', ev.target.value)}
              />
            </Form.Item>
            <Form.Item
              label="Username:"
              validateStatus={errors.custom_proxy_username && touched.custom_proxy_username ? 'error' : ''}
              hasFeedback
              help={errors.custom_proxy_username && touched.custom_proxy_username ? errors.custom_proxy_username : ''}
            >
              <Input
                name="custom_proxy_username"
                value={values.custom_proxy_username}
                placeholder="Type the username"
                onChange={ev => setFieldValue('custom_proxy_username', ev.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Port:"
              validateStatus={errors.custom_proxy_port && touched.custom_proxy_port ? 'error' : ''}
              hasFeedback
              help={errors.custom_proxy_port && touched.custom_proxy_port ? errors.custom_proxy_port : ''}
            >
              <Input
                name="custom_proxy_port"
                value={values.custom_proxy_port}
                placeholder="Type the port"
                onChange={ev => setFieldValue('custom_proxy_port', ev.target.value)}
              />
            </Form.Item>

            <Form.Item
              label="Password:"
              validateStatus={errors.custom_proxy_password && touched.custom_proxy_password ? 'error' : ''}
              hasFeedback
              help={errors.custom_proxy_password && touched.custom_proxy_password ? errors.custom_proxy_password : ''}
            >
              <Input.Password
                autoComplete="new-password"
                value={values.custom_proxy_password}
                name="custom_proxy_password"
                className={errors.custom_proxy_password && touched.custom_proxy_password ? 'invalid-input' : ''}
                onChange={ev => setFieldValue('custom_proxy_password', ev.target.value)}
                onBlur={handleBlur}
                placeholder={proxy?.custom_proxy_password ? '*******************' : 'Type your password'}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </>
  );
}
