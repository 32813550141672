import {connect} from 'react-redux';

import {LazyBillingDetails} from '@growth-x/ui';

import {billingActions} from '../../redux/billing';
import {headerModalActions} from '../../redux/headerModal';

function mapState(state: any) {
  return {
    client: state.client.data,
    isAdmin: state.settings.isAdmin,
    info: state.billing.info,
    contactEmails: state.billing.contactEmails,
    affiliateCustomers: state.billing.affiliateCustomers,
    affiliateSubscription: state.billing.affiliateSubscription,
    affiliateCustomerId: state.billing.affiliateCustomerId,
    loading: state.billing.loading,
  };
}

const actionCreators = {
  onClose: () => headerModalActions.setHeaderModal(undefined),
  setHeaderModal: headerModalActions.setHeaderModal,
  onUpdate: billingActions.updateSubscription,
  onUpdateSelfserve: billingActions.updateSelfserveSubscription,
  endTrial: billingActions.endTrial,
  onUpdateStatus: billingActions.updateSubscriptionStatus,
  onRemoveCoupon: billingActions.removeBillingCoupon,
  onGetInfo: billingActions.getBillingInfo,
  getBillingContactEmails: billingActions.getBillingContactEmails,
  selfserveReactivate: billingActions.selfserveReactivate,
  removeScheduledChanges: billingActions.removeScheduledChanges,
  createBillingContactEmail: billingActions.createBillingContactEmail,
  updateBillingContactEmail: billingActions.updateBillingContactEmail,
  deleteBillingContactEmail: billingActions.deleteBillingContactEmail,
  upsertBillingContactEmail: billingActions.upsertBillingContactEmail,
  moveFromPromoPlan: billingActions.moveFromPromoPlan,
  getCustomers: billingActions.getAffiliateCustomer,
  createAffiliateSubscription: billingActions.createAffiliateSubscription,
  getAffiliateSubscription: billingActions.getAffiliateSubscription,
};

export const BillingDetailsContainer = connect(mapState, actionCreators)(LazyBillingDetails);
